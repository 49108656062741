import { OpenGraphTypes } from '@/components/Marketing/DocumentHead'
import { socialLinks } from '@/constants/socialLinks'
import { objectStorageUrl } from '@/utils/objectStorageUrl'
import { type BreadcrumbList, type Article, type Graph } from 'schema-dts'

type Props = {
  title?: string
  slug: string
  description: string
  type: OpenGraphTypes
  publishedTime?: string
  imageUrl?: string
}

export const buildStructuredDataByPage = ({
  title = 'Shipt Grocery Delivery',
  slug,
  description,
  type,
  publishedTime,
  imageUrl,
}: Props): Graph => {
  const pageDescription = description ? { description } : {}
  const pageUrl = `https://www.shipt.com${slug}/`

  const articleData: Article = {
    '@type': 'Article',
    '@id': `${pageUrl}#article`,
    isPartOf: { '@id': pageUrl },
    headline: title,
    datePublished: publishedTime,
    mainEntityOfPage: { '@id': pageUrl },
    publisher: { '@id': 'https://www.shipt.com/#organization' },
    image: { '@id': `${pageUrl}#primaryimage` },
    thumbnailUrl: imageUrl,
    inLanguage: 'en-US',
  }

  const breadcrumbData: BreadcrumbList = {
    '@type': 'BreadcrumbList',
    '@id': `${pageUrl}/#breadcrumb`,
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://www.shipt.com/',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Blog',
        item: 'https://www.shipt.com/blog/',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: title,
      },
    ],
  }

  return {
    '@context': 'https://schema.org',
    '@graph': [
      {
        '@type': 'Organization',
        '@id': 'https://www.shipt.com/#organization',
        name: 'Shipt Same-day Delivery',
        url: 'https://www.shipt.com/',
        sameAs: Object.values(socialLinks),
        logo: {
          '@type': 'ImageObject',
          '@id': 'https://www.shipt.com/#logo',
          url: objectStorageUrl('seo-logo.png'),
          width: '90',
          height: '30',
          caption: 'Shipt Same-day Delivery',
        },
        image: { '@id': 'https://www.shipt.com/#logo' },
        areaServed: 'US',
        mainEntityOfPage: 'https://www.shipt.com/shop',
        contactPoint: [
          {
            '@type': 'ContactPoint',
            telephone: '+1-205-502-2500',
            contactType: 'customer service',
            contactOption: ['HearingImpairedSupported', 'TollFree'],
            areaServed: 'US',
            availableLanguage: [
              {
                '@type': 'Language',
                name: 'English',
              },
            ],
          },
        ],
        telephone: '+1-205-502-2500',
        email: 'support@shipt.com',
        address: {
          '@type': 'PostalAddress',
          addressLocality: 'Birmingham, Alabama',
          postalCode: '35203',
          streetAddress: '420 20th St N, Suite 100',
        },
      },
      {
        '@type': 'WebSite',
        '@id': 'https://www.shipt.com/#website',
        url: 'https://www.shipt.com/',
        name: 'Shipt Grocery Delivery',
        description: 'On-demand Grocery Delivery',
        publisher: { '@id': 'https://www.shipt.com/#organization' },
        potentialAction: [
          {
            '@type': 'SearchAction',
            target: {
              '@type': 'EntryPoint',
              urlTemplate: 'https://www.shipt.com/?s={search_term_string}',
            },
            // @ts-expect-error - https://github.com/google/schema-dts/issues/114
            'query-input': 'required name=search_term_string',
          },
        ],
      },
      {
        '@type': 'WebPage',
        '@id': `${pageUrl}#webpage`,
        url: pageUrl,
        inLanguage: 'en-US',
        name: title,
        ...pageDescription,
        isPartOf: { '@id': 'https://www.shipt.com/#website' },
      },
      ...(type === OpenGraphTypes.ARTICLE ? [articleData, breadcrumbData] : []),
    ],
  }
}
